// -----------------------------------------------------------------------------
// This file contains all vendor-overrides
// -----------------------------------------------------------------------------
.dropdown-item {
    &:hover {
        background: unset;
        background: transparent;
    }
}

.bootstrap-filestyle {
 input {
    display: flex;
    width: 100% !important;
    background-color: transparent !important;
    border: 0;
    order: 2;
    padding-left: 0;
    margin-top: 5px;
    font-size: 10px !important;
 }
 > span {
     display:flex;
     order: 1;
     border: 1px solid $font-general;
     background-color: transparent;
     border-radius: 4px;
     
     label {
         background-color: transparent;
         border: 0;
         color: $font-general;
         span {
            font-weight: 500 !important;
         }
        &:hover {
        background-color: lightgray;
        color: $font-general;
        }
     }
 }
}
.popover {
    max-width: 720px !important;
    &-header {
        background-color: white;
        border: 0;
    }
  }

    // Mobile specific styling
    @media (max-width: $tablet) {
        .popover {
            margin-left: 10px;
        }
    }