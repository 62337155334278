.case-studies--modal {
// General styling
a.project-link {
    text-decoration: underline;
    font-weight: 500;
    font-size: 14px;
    &:hover {
        color: #0984E3;
    }
}
.modal {
&-dialog {}
&-content {}
&-header {
    button.close {
        z-index: 2;
        right: 70px;
        position: absolute;
    }
}
&-body {
    .card {
        padding: 100px;
        &-header {
            padding: 0;
            background: transparent;
            color: $brand-secondary;
            border: 0;
            ul {
                padding: 0;
                li {
                    display: inline-block;
                    &.civic-data-lab {
                        color: white;
                        background: black;
                        padding: 7px 15px;
                        border-radius: 31px;
                        margin-top: 10px;
                    }
                    &.tool {
                        color: #F47181;
                        margin-left: 30px;
                        &::before {
                            content: ' ';
                            display: block;
                            width: 35px;
                            height: 35px;
                            border-radius: 17.5px;
                            background:#F47181;
                            margin-right: 10px; 
                            display: inline-block;
                            bottom: -12px;
                            position: relative;
                        }
                        &.data-tool{
                            color: $font-datatool;
                            &::before { background: $datatool; }
                        }
                        &.open-data-portal {
                            color: $font-open-data-portal;
                            &::before { background: $open-data-portal; }
                        }
                        &.data-journalism {
                            color: $font-data-journalism;
                            &::before { background: $data-journalism; }
                        }
                        &.research {
                            color: $font-research;
                            &::before { background: $research; }
                        }
                        &.media-analysis {
                            color: $font-media-analysis;
                            &::before { background: $media-analysis; }
                        }
                        &.accountability-platform {
                            color: $font-accountability-platform;
                            &::before { background: $accountability-platform; }
                        }
                        &.citizens-and-city {
                            color: $font-citizens-and-city;
                            &::before { background: $citizens-and-city; }
                        }
                        &.toolkit{
                            color: $font-toolkit;
                            &::before { background: $toolkit; }
                        }
                    }
                }
            }
            hr {
                border-top: 1px solid;
                width: 100%;
                color: black;
                opacity: 1;
            }
            .logo {
                width: 20%;
                padding-right: 30px;
            }
            .text {
                width: 80%;
                .tags {
                    margin-top: 15px;
                    height: 60px;
                    overflow: hidden;
                }
                .status {
                    margin-top: 10px;
                    strong {
                        font-style: oblique;
                    }
                    .complete {
                        color: #00B894;
                    }
                }
            }
            .status-bar {
                .inner-div {
                    width: 100%;
                }
                img {
                    width: 100%;
                    padding: 30px 0;
                }
            }
            .title {
                * {
                    display: inline-block;
                }
                h3 {
                    font-size: 26px;
                    line-height: 27px;
                    font-weight: 700;
                    color: $font-general;
                }
                span {
                    font-size: 20px;
                    font-weight: 500;
                }
            }
        }
        &-body {
            h3 {
                font-size: 25px;
                margin-bottom: 0;
                width: 100%;
            }
        }
        &-footer {
            background: transparent;
            h3 {
                font-size: 25px;
                display: block;
                width: 100%;
                margin-bottom: 15px;
            }
            ul {
                padding: 0;
                margin: 0 0 -15px;
                li {
                    display: inline-block;
                    font-style: oblique;
                    font-weight: 500;
                }
            }
            .partners {
                padding: 30px 0;
                img {
                    display: inline-block;
                    width: 30%;
                    height: 30%;    
                    padding: 15px 30px 0 0;
                }
            }
        }
    }
}

}
// Desktop specific styling
@media (min-width: $tablet) {
}

  // Mobile specific styling
  @media (max-width: $tablet) {
  }
}