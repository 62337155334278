.about-us {
  // General styling
  &.wrapper {
    padding-bottom: 0;
  }

  p {
    font-size: 16px;	
    line-height: 26px;
    margin-bottom: 0;
  }
  .who-we-are {
    margin-top: 120px;
    img {
      margin-left: auto;
      margin-right: auto;
      display: block;
      max-width: 100%;
    }
    svg {
      width: 100%;
    }
  }
  .what-we-do {
  h2 ~ p {
    // margin-bottom: 30px;
  }
  h3 {
    font-size: 20px;	
    line-height: 26px;
    text-transform: uppercase;
    font-weight: 400;
  }
  svg ~ div p {
    margin-top: 30px;
  }
  img {
    max-width: 266px;
    max-height: 266px;
    height: auto;
    margin: 15px 0;
  }
  }
  .how-we-do {
    .circle {
      height: 160px;	
      width: 160px;	
      border-radius: 80px;
      display: block;
      margin: auto;
      &.red {
        background-color: #F47181;
      }
      &.orange {
        background-color: #FFD48D;
      }
      &.yellow {
        background-color: #FFF689;
      }
      &.purple {
        background-color: #E0A0ED;
      }
      &.blue {
        background-color: #97C7FF;
      }
      &.green {
        background-color: #BCE88C;
      }
      &.turqoise {
        background-color: #99EBD9;
      }
      &.outline {
        border: 2.46px solid #4A4A4A;	
      }
    }
      span {
        text-align: center;
        color: $font-general;
        font-size: 14px;
        line-height: 18px;
        margin-top: 10px;
        display: block;

      }
      .carousel {
        &-inner {
          
        }
        &-item {

        }
      }
  }
  .card {
    &-header {
      display: none;
    }
    &-body {
     //
    }
    &-footer {
    //
    }
  }
  .quote {
    background: #0984E3;
    margin-top: 120px;
    p {
      color: white;
      font-size: 30px;	
      line-height: 45px;
      padding: 120px 0;
      text-align: center;
      font-weight: 200;
      font-family: 'Barlow';
    }
  }
  // Desktop specific styling
  @media (min-width: $tablet) {
    svg {
      margin-top: 30px;
      // width: unset !important;
    }
  }
  // Mobile specific styling
  @media (max-width: $tablet) {
    img {
      margin-top: 30px; 
    }
    svg {
      margin:15px 0px;
      width: 100% !important;
    }
    p, h1, h2, h3, h4 {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  }
  @media (max-width: $desktop) {
    svg {
      width: 100% !important;
    }
  }
}