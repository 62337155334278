// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-display: swap; // To make text visible before web fonts load
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}


body {
  height: 100vh;
}

ul {
  li {
    list-style: none;
  }
}

/**
 * Basic styles for links
 */
a {
  color: $brand-primary;
  text-decoration: none;

  @include on-event {
    color: $font-general;
    text-decoration: underline;
  }
}

input {
  width: 100%;
}

hr {
  border-top: 2px solid;
  opacity: .3;
  max-width: 920px;
  margin: auto;
}

a {
  cursor: pointer;
}

// Desktop specific styling
@media (min-width: $tablet) {
}

  // Mobile specific styling
  @media (max-width: $tablet) {

  }