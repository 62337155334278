.case-studies {
  // General styling
  background: #DFE6E9;

  .container-fluid {
    background: #F2F2F2;
    margin-top: 75px;
    padding-bottom: 75px;
  }

  &--intro {
    box-shadow: $box-shadow-large;
    padding-bottom: 30px;
    background: white;
  }

  &--container {
    padding-top: 50px;
  }

  &--filter {
    margin-bottom: 30px;

    span {
      display: inline-block;
    }

    ul {
      display: inline-block;
      padding: 0;

      li {
        display: inline-block;

        a {
          font-size: 18px;
          line-height: 21px;
          margin-left: 30px;
          transition: .1s color ease-out;

          &.active {
            color: $brand-primary;
            font-weight: 700;
            text-decoration: underline;
          }

          &:hover {
            color: $brand-primary !important;
          }
        }
      }
    }
  }

  &--cases {

      .card {
        &-body {
          padding: 0;
        }
      }
  }

  // Desktop specific styling
  @media (min-width: $tablet) {
    &--intro {
      padding-bottom: 75px;
    }
  }

  // Mobile -> Tablet specific styling
  @media (max-width: $tablet) {
    &--filter {
      display: none;
    }

    p,
    h1,
    h2,
    h3,
    h4 {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  }

  // Mobile specific styling
  @media (max-width: $mobile) {
    .container-fluid {
      margin-top: 30px;
      padding-bottom: 30px;
    }

    .case-study-modal-trigger {
      pointer-events: none;
    }

    .advise {
      margin-bottom: 10px !important;
    }
  }
}