// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

/// Make a context based selector a little more friendly
/// @author Hugo Giraudel
/// @param {String} $context
@mixin when-inside($context) {
  #{$context} & {
    @content;
  }
}
//Job Posts
//mobile
@mixin job-card-content-mobile{
  position: absolute;
  padding:6px 0;
  color: $font-general;
  font-family: $font-family-primary;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 16px;
}
//Desktop
@mixin job-card-content{
  padding:2px 5px;
  color: $font-general;
  font-family: $font-family-primary;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 26px;
}

