.header--navigation {

  // General styling
  position: fixed;
  top: 0;
  z-index: 16;
  width: 100%;
  padding: 30px 0;
  transition: .2s all ease-in-out;
  background: transparent;
  &.fill {
    background: $brand-secondary;
    box-shadow: 0px 9px 9px 0px rgba(0,0,0,0.1);
    padding: 0;
  }
  &.logo {
    .navbar-brand {
      opacity: 1;
    }
  }
  .navbar {
    padding: 0;
    height: $navigation-height-desktop;
    &-brand {
      opacity: 1;
      transition: .2s all ease-in-out;
      img {
        height: 32px;
        width: 256px;
      }
    }
    &-toggler {
      border: 0;
    }
    &-nav {
      padding: 15px 0;
      .nav-item {
        display: block;
        margin: 0px 15px;
      }
      .nav-link {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        text-transform: lowercase;
        transition: .1s color ease-in;
        &:hover {
          color: $brand-primary;
        }
      }
    }
    &-light {
      .nav-link {
        color: $font-general;
      }
    }
    &-dark {
      .nav-link {
        color: white;;
      }
    }
  } 
  
  // Desktop specific styling
  @media (min-width: $tablet) {

  } 

  // Mobile specific styling
  @media (max-width: $tablet) {
    position: fixed;
    width: 100%;
    &.dropshadow {
      .navbar {
        &-collapse {
          top: calc(#{$navigation-height-mobile} + 3px);
        }
      }
    }
    .navbar {
      &-collapse {
        z-index: 1;
        padding: 0 30px;
        margin: 0 -39px;
        position: absolute;
        top: $navigation-height-mobile;
        width: calc(100% + 80px);
        .nav-item {
          text-align: center;
        }
          .nav-link {
            font-size: 16px;
            font-weight: 500;
          }
      }
      &-light {
        //
      }
      &-dark {
        .navbar-collapse {
          background: $brand-secondary;
        }
      }
    }
  }

  @media (max-width: $mobile) {
    padding: 0;
    .navbar {
      &-collapse {
        padding: 0 15px;
        margin: 0 -15px;
        width: calc(100% + 30px);
        background: white;
        box-shadow: 0px 9px 9px 0px rgba(0,0,0,0.1);
      }
      &-brand img {
        max-width: 200px;
        width: unset;
        height: auto;
      }
    }
  }
}

.home .home, .about .about, .careers .careers{
  font-weight: 700 !important;
  
}