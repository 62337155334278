.filter-bar {

  // General styling
text-transform: uppercase;
.col {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: baseline;
}
hr {
  border-top: 2px solid;
  width: calc(100% - 30px);
  color: rgba(74, 74, 74, 0.5);
}
.select-label {
  display: block;
  font-size: 10px;
  width: calc(100% - 30px);
  margin: auto;
}
.dropdown {
  a {
    text-transform: capitalize;
    display: block;
    border: $border;
    padding: 2px 5px;
    border-radius: $border-radius;
    color: #9B9B9B;
  }
  span {
    font-size: 14px;
  }
  i {
    float: right;
    margin-top: 3px;
    transition: .3s transform ease-in-out;
    color: $font-general;
  }
  &.show {
    i {
      transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
    }
  }
  &-menu {
    &.show {
      padding: 0;
      width: 100%;
      span {
        padding: 5px;
        display: block;
        text-transform: capitalize;
        &:hover {
          cursor: pointer;
          background: #DCDCE2;
          text-decoration: underline;
        }
      }
    }
  }
}

&--notice-label {
  display: inline-block;
  margin-left: 30px;
}
&--notice-number {
  font-size: 17px;
  font-weight: 500;
  margin-left: 5px;
}
.pagination {
  display: inline-block;
  float: right;
  &--title {
    margin: 0 10px;
    span {
      font-size: 17px;
      font-weight: 500;
      border-bottom: 2px solid rgba(74, 74, 74, 0.3);
      margin: 0 10px;
      cursor: pointer;
    }
  }
  &--controls {
    opacity: 0.3;
    cursor: pointer;
    transition: .1s all ease-in-out;
    &:hover {
      opacity: 0.7;
    }
    &_right {
      //
    }
    &_left {
      margin: 0 10px;
    }
  }
}

// Desktop specific styling
@media (min-width: $tablet) {
  .dropdown {
    width: 250px;
  }
}

// Mobile specific styling
@media (max-width: $tablet) {
  &--notice-label {
    margin-left: 5px;
  }
  .pagination--title {
    margin-left: 5px;
    span {
        margin: 0 5px;
      }
     }
     form {
       .select2-container {
        width: 100px !important;
       }
     }
  }
}