.partners {
// General styling
.collection {
  img {
    margin: 40px 0;
    height: auto;
    max-width: 305px;
  }
  .col-md-4:nth-of-type(2),
  .col-md-4:nth-of-type(3){
    img {
      height: auto;
    }
  }
}
// Desktop and upward specific styling
@media (min-width: $tablet) {
}

// Tablet and upward specific styling
@media (min-width: $mobile) {
}

// Mobile and downward specific styling
  @media (max-width: $mobile) {
    .collection {
      img {
        width: 100%;
      }
    }
  }
}