.with-thanks-to {

  // General styling
  img {
    height: 230px;
    margin: 40px 0;
  }

  .collection {
    img {
      height: 80px;
    }
  }

  // Desktop and upward specific styling
  @media (min-width: $tablet) {
    .collection {
      div {
        &:nth-of-type(4n) {
          img {
            float: right;
          }
        }
      }
    }
    img {
      width: unset;
    }
  }

  // Tablet and upward specific styling
  @media (min-width: $mobile) {}

  // Mobile and downward specific styling
  @media (max-width: $mobile) {
    .collection {
      img {
        width: 100%;
      }
    }
  }
}