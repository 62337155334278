.splash--content {
// General styling
> .row {
  height: 100vh;
}
.card {
  width: 540px;
  height: 575px;
  box-shadow: 0 4px 9px 4px rgba(0,0,0,0.1);
  border-radius: 0;
  border: 0;
  padding: 15px;
  h3 {
    font-weight: 400;
  }
  span {
    text-transform: uppercase;
    font-size: 26px;
    line-height: 32px;
    &.yellow {
      color: #E7AA42;
    }
    &.red {
      color: #EA7F66;
    }
    &.black {
      color: #303643;
    }
  }
  .logo {
    max-width: 396px;
    margin-top: 80px;
  }
  .btn {
      box-sizing: border-box;
      height: 31px;
      line-height: 19px;
      width: 288px;
      border: 1px solid #2F3442;
      border-radius: 15px;
      margin-top: 80px;
      &:hover {
        box-shadow: $box-shadow;
      }
  }
}

// Desktop specific styling
@media (min-width: $tablet) {
}

  // Mobile specific styling
  @media (max-width: $tablet) {
  }
}