.competencies{
    margin-bottom: 30px;
    .row{
        .col-md-3{
            p{
                color: #4A4A4A;
                font-size: 18px;
                font-weight: 500;
                line-height: 20px;
                text-align: center;
                text-transform: uppercase;
            }
            &.big-dot{
                p{
                    font-size: 22px;
                    color: #FFFFFF;
                    font-weight: bold;
                    line-height: 30px;
                    position: absolute;
                    text-align: center;
                    top: 134px;
                    text-transform: lowercase;
                }
            }
        }
    }
      // Mobile specific styling
    @media (max-width: $tablet) {
        .row{
            .col-md-3{
                &.big-dot{
                    p{
                        top: 65px;
                    }
                }
            }
        }
    }
}