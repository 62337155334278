.wrapper {
    padding: 75px 0;
}

.background-filler {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: .8;
}

.block {
    display: block;
}

.dashed {
    border-top: 2px dashed $font-general;
}

.inline-block {
    display: inline-block;
}

.inline {
    display: inline;
}

.no-margin {
    margin: 0 !important;
}

.no-padding {
    margin: 0 !important;
}

.last {
    margin: 0 !important;
}

.modal-only {
    display: none;
}

.label {
    text-transform: uppercase;
    font-size: 10px;
    line-height: 1.5;
}
.popover-header{
    padding-left: 21px;
    padding-top: 21px;
}
.popover-body{
    font: normal 400 16px/1.5 "Montserrat";
    padding: 21px;
}
.margintop {
    margin-top: 70px;
}

.hover-rise {
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
    position: relative;
    display: inline-block;
    transition: 0.2s all ease-out;

    &:hover {
        transform: scale(1.02);
        box-shadow: 0px 4px 4px 1px #b7b7b7b3;

    }
}
.card-shadow {
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
    position: relative;
    display: inline-block;
    transition: 0.2s all ease-out;

}

// Desktop specific styling
@media (min-width: $tablet) {}

// Mobile specific styling
@media (max-width: $tablet) {
    .wrapper {
        padding: 35px 0 !important;
    }

    .margintop {
        margin-top: 0;
    }

    .fo-1 {
        order: 1;
    }
}