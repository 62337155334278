.organisations {
// General styling
hr {
  margin-top: 75px;
}
.row {
  p {
    font-size: 16px;
    text-align: center;
  }
  .row {
    margin-top: 75px;
    img {
      height: 70px;
      width: auto;
    }
    &:first-of-type {
      img {
        max-width: 150px;
        height: auto;
        &.tenji {
          max-width: 156px;
        }
        &.ngolaw {
          max-width: 203px;
        }
      }
    }
  }
}

// Desktop specific styling
@media (min-width: $tablet) {
}

  // Mobile specific styling
  @media (max-width: $tablet) {
    img {
      max-width: 200px;
      height: auto !important;
      margin: 30px 0;
    }
    .row .row {
      margin-top: 0;
    }
  }
}