.contact {
  // General styling
  box-shadow: 0px 11px 5px 15px rgba(0, 0, 0, 0.2);
  position: relative;
  .Durban-box{
    position: absolute;
    width: 6%;
    height: 10%;
    top: 67%;
    right: 17%;
  }
  .Durban-pin{
    position: absolute;
    width: 2%;
    height: 3%;
    top: 46%;
    right: 39%;
  }
  .Johannessburg-pin{
    position: absolute;
    width: 2%;
    height: 3%;
    top: 31%;
    right: 43%;
  }
  .Johannessburg-box{
    position: absolute;
    width: 6%;
    height: 10%;
    top: 18%;
    right: 17%;
  }
  .Cape-Town-pin{
    position: absolute;
    width: 2%;
    height: 3%;
    top: 62%;
    right: 62%;
  }
  .Cape-Town-box{
    position: absolute;
    width: 7%;
    height: 10%;
    top: 67%;
    right: 79%;
  }
  a {
    display: block;
  }

  h2 {
    margin-bottom: 50px;
  }

  h3 {
    margin-bottom: 0;
  }

  img {
    width: 100%;
  }

  .dashed {
    margin-bottom: 70px;
  }

  // Desktop specific styling
  @media (min-width: $tablet) {}

  // Mobile specific styling
  @media (max-width: $tablet) {
    img {
//       margin-bottom: 30px;
    }

    p,
    a,
    h1,
    h2,
    h3,
    h4 {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  }
}