.job-posting {
  margin-bottom: 100px;
  padding-top: 20px;

  h4 {
    color: $font-general;
    font-family: $font-family-primary;
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
    margin-bottom: 9px;
  }
  p {
    color: $font-general;
    font-family: $font-family-primary;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 26px;
    margin-right: 30px;
    margin-bottom: 30px;
  }
  &-card {
    height: 56px;
    opacity: 0.93;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 0 9px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;

    ul {
      padding: 0;
      display: flex;
      justify-content: space-between;
    }
    li {
      margin-top: 15px;
      .junior-designer,
      .software-developer {
        @include job-card-content-mobile;
      }
    }
  }
  &-button {
    box-sizing: border-box;
    height: 30px;
    padding: 5px 6px;
    border: 1px solid #3a3a40;
    border-radius: 17.5px;
    background-color: #ffffff;
    margin: 8px 11px;
    font-size: 12px;
    margin-top: 12.2px;
    font-weight: 600;
    font-family: $font-family-primary;
    font-size: 12px;
  }
  &-button:hover {
    text-decoration: none;
    background-color: #3a3a40;
    color: white;
  }
}

@media screen and (max-width: 369px) {
  .job-posting {
    margin-bottom: 100px;
  
    h4 {
      color: $font-general;
      font-family: $font-family-primary;
      font-size: 25px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 30px;
      margin-bottom: 9px;
    }
    p {
      color: $font-general;
      font-family: $font-family-primary;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 26px;
      margin-right: 30px;
      margin-bottom: 30px;
    }
    &-card {
      height: 56px;
      opacity: 0.93;
      border-radius: 3px;
      background-color: #ffffff;
      box-shadow: 0 0 9px 4px rgba(0, 0, 0, 0.1);
      margin-bottom: 30px;
  
      ul {
        padding: 0;
        display: flex;
        justify-content: space-between;
      }
      li {
        margin-top: 15px;
        .junior-designer,
        .software-developer {
          @include job-card-content-mobile;
        }
      }
    }
    &-button {
      box-sizing: border-box;
      height: 30px;
      width: 40px;
      padding: 5px 6px;
      border: 1px solid #3a3a40;
      border-radius: 17.5px;
      background-color: #ffffff;
      margin: 8px 11px;
      margin-top: 12.2px;
      font-weight: 600;
      font-family: $font-family-primary;
      font-size: 8px;
    }
    &-button:hover {
      text-decoration: none;
      background-color: #3a3a40;
      color: white;
    }
  }
}
@media screen and (max-width: 369px) {
  .job-posting {
    margin-bottom: 100px;
  
    h4 {
      color: $font-general;
      font-family: $font-family-primary;
      font-size: 25px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 30px;
      margin-bottom: 9px;
    }
    p {
      color: $font-general;
      font-family: $font-family-primary;
      font-size: 13px;
      letter-spacing: 0;
      line-height: 26px;
      margin-right: 30px;
      margin-bottom: 30px;
    }
    &-card {
      height: 56px;
      opacity: 0.93;
      border-radius: 3px;
      background-color: #ffffff;
      box-shadow: 0 0 9px 4px rgba(0, 0, 0, 0.1);
      margin-bottom: 30px;
  
      ul {
        padding: 0;
        display: flex;
        justify-content: space-between;
      }
      li {
        margin-top: 15px;
        .junior-designer,
        .software-developer {
          @include job-card-content-mobile;
        }
      }
    }
    &-button {
      box-sizing: border-box;
      height: 30px;
      width: 40px;
      padding: 5px 6px;
      border: 1px solid #3a3a40;
      border-radius: 17.5px;
      background-color: #ffffff;
      margin: 8px 11px;
      margin-top: 12.2px;
      font-weight: 600;
      font-family: $font-family-primary;
      font-size: 8px;
    }
    &-button:hover {
      text-decoration: none;
      background-color: #3a3a40;
      color: white;
    }
  }
}
// Desktop specific styling
@media (min-width: $tablet) {
  .job-posting {
    width: 70%;
    margin: auto;
    &-card {
      ul {
        li {
          margin-top: 16px;
          .junior-designer,
          .software-developer {
            @include job-card-content;
          }
        }
      }
    }
    &-button {
      box-sizing: border-box;
      height: 30px;
      width: 225px;
      padding: 5px 30px;
      border: 1px solid #3a3a40;
      border-radius: 17.5px;
      background-color: #ffffff;
      color: #2f3442;
      font-weight: 600;
      font-family: $font-family-primary;
      font-size: 14px;
    }
    &-button:hover {
      text-decoration: none;
      background-color: #3a3a40;
      color: white;
    }
  }
}
