.splash {
// General styling
background: url("/odd/static/dist/img/image_bg_redirect-page.png") center center no-repeat / 100% 100vh;

.header--navigation, footer, .userback-button-container {
  display: none;
}

// Desktop specific styling
@media (min-width: $tablet) {
}

  // Mobile specific styling
  @media (max-width: $tablet) {
  }
}