// ODD - Design Spec

// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

// Colour branding
$brand-primary:        	#D63031;
$brand-secondary:   	white;
$brand-tertiary:         	#636E72;
$background-colour:	#F5F7F8;
$font-general:          		#4A4A4A;
$font-button-primary:  	#FFFFFF;
$font-button-secondary:  	#FDCB6E;
$font-icons:               		#4A4A4A;
$button_primary:    		#FDCB6E;
$button_secondary: 	#FFFFFF;

$datatool:			#FFEAA7;
$open-data-portal:			#FF7675;
$data-journalism:			#FDCB6E;
$research:				#A29BFE;
$media-analysis:			#74B9FF;
$accountability-platform:		#FAB1A0;
$citizens-and-city:			#55EFC4;
$toolkit:				#81ECEC;

$font-datatool:		#DBC88B;
$font-open-data-portal:		#DD6B6A;
$font-data_journalism:		#DDB263;
$font-research:			#8C86DC;
$font-media_analysis:		#6BABEC;
$font-accountability_platform:	#CD9487;
$font-citizens_and_city:		#4FD6B0;
$font-toolkit:				#74CFCF;

// Typography
$font-family-primary: 	'Montserrat';
$font-icon-family: 'Font Awesome 5 Free';
$h1: 				normal 700 30px/1.5 $font-family-primary;
$h2: 				normal 700 25px/1.5 $font-family-primary;
$h3: 				normal 700 18px/1.5 $font-family-primary;
$h4: 				uppercase 500 10px/1.5 $font-family-primary;
$h5: 				normal 600 16px/1.5 $font-family-primary;
$p: 				normal 400 16px/1.5 $font-family-primary;
$p-med:            		normal 500 14px/1.5 $font-family-primary;
$p-bold:            		normal 700 14px/1.5 $font-family-primary;
$span: 			normal 500 16px/1.5 $font-family-primary;

// Device sizes
$desktop: 1366px;
$tablet: 768px;
$mobile: 480px;

// Grid Sizes
$content-grid: 1140px;
$side-bar-width: 350px;

// Navigation Sizes
$navigation-height-desktop: 	60px;
$navigation-height-mobile: 	50px;

 

// Global Elements
$box-shadow: 	0px 0px 5px 2px rgba(0,0,0, 0.1);
$box-shadow-large: 0 3px 3px 0 rgba(0,0,0,0.3);
$text-shadow: 	None;
$border: 		1px solid $font-general;
$border-radius: 	5px;

// Module Spacing
$module-margin: 			15px;
$module-padding: 			20px;
$module-bottom-padding: 	30px;
$hr-margin: 				15px 0px;
