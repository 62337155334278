.work-for-us {
  margin-top: 80px;
     position: relative;
  h4 {
    color: $font-general;
    font-family: $font-family-primary;
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
  }
  ul {
    padding: 0;
    
    li {
      p {
        color: $font-general;
        font-family: $font-family-primary;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 26px;
        margin-right: 55px;
        margin-bottom: 30px;
        
        
      }
      a {
        color: #eab04b;
        text-decoration: underline;
      }
      .rectangle {
        cursor: pointer;
        margin: auto;
        height: 160px;
        width: 155px;
        opacity: 0.93;
        border-radius: 3px;
        background-color: #ffffff;
        box-shadow: 0 0 9px 4px rgba(0, 0, 0, 0.1);
        overflow: auto;
        margin-bottom: 26px;
        .heart-logo {
          position: absolute;
          height: 80px;
          width: 90px;
          margin: auto;
          display: block;
          margin-top: 15px;
          margin-left: 35px;
        }
        .read-mission-statement {
          position: absolute;
          height: 40px;
          width: 198px;
          color: $font-general;
          font-family: $font-family-primary;
          font-size: 11px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 15px;
          text-align: center;
          bottom: -10px;
          margin-left: -20px;
        }
      }
      .rectangle:hover {
        box-shadow: 0 0 13px 4px rgba(0, 0, 0, 0.1);
      }
    }
  }
}
// Desktop specific styling
@media (min-width: $tablet) {
  .work-for-us {
    margin-top: 200px;
    position: relative;
    margin: 175px auto 0;
    width: 70%;
    ul {
      display: flex;
      li {
        .rectangle {
          position: relative;
          margin: auto;
          height: 260px;
          width: 255px;
          opacity: 0.93;
          border-radius: 3px;
          background-color: #ffffff;
          box-shadow: 0 0 9px 4px rgba(0, 0, 0, 0.1);
          overflow: auto;
          .heart-logo {
            position: absolute;
            height: 120px;
            width: 135px;
            margin: auto;
            display: block;
            top: 50px;
            left: 60px;
          }
          .read-mission-statement {
            position: absolute;
            color: $font-general;
            font-family: $font-family-primary;
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 20px;
            text-align: center;
            top: 190px;
            left: 50px;
          }
        }
      }
    }
  }
}
