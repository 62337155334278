.about {
// General styling
.contact {
  box-shadow: none;
}
.navbar-brand {
  opacity: 1 !important;
}
// Desktop specific styling
@media (min-width: $tablet) {
}

  // Mobile specific styling
  @media (max-width: $tablet) {
  }
}