.card {
  // General styling
    border-radius: 20px;
    overflow: hidden;
    img {
      width: 100%;
    }
    h1 {
      font-size: 35px;
    }
    > .row {
    //   padding: 15px;
    }
    &-header {
      background: $brand-secondary;
      color: white;
      padding: 7px 20px 7px;
    }
    &-title {
        margin-bottom: 0;
    }
    &-subtitle {
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 200;
      span {
        text-transform: capitalize;
        font-size: 14px;
        font-weight: 400;
        margin-left: 5px;
      }
    }
    &-body {
      padding: 15px 5px;
      ol {
        font-weight: 400;
        li {
          font-size: 14px;
          font-weight: 400;
        }
      }
      hr {
          border-top: 2px solid;
          margin: 10px 0;
          color: #9B9B9B;
      }
      ul {
        li {
          font-size: 14px;
          font-weight: 400;
          list-style-type: disc;
          text-decoration: none;
        }
      }
    }
    &-footer {
      padding: 0;
      background-color: #E6E6E6;
      border-top: 0;
    }
    
    
    // Desktop specific styling
    @media (min-width: $tablet) {
    }
    
      // Mobile specific styling
      @media (max-width: $tablet) {
        &--title {
          font-size: 18px;
        }
        &-header {
          &--cta {
            width: 100%;
            justify-content: flex-end;
          }
        }
      }
    }
