.footer {
// General styling
background: #3A3A40;
padding: 20px;
color: white;
position: sticky;
top: 100vh;
&--info {
    p {
      font-size: 12px;
      margin: 0;
    }
    img {
      width: 140%;
    }
}
&--email {
  text-align: center;
  span {
    font-weight: 200;
    font-size: 12px;
    text-transform: uppercase;
    a {
      font-weight: 400;
      font-size: 16px;
      text-transform: lowercase;
    }
  }
}
&--logo {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
}
 &--img {
   height: 30px;
   max-width: 100%;
 }
&--social-media {
  .col {
    justify-content: center;
    flex-flow: row;
    display: flex;
    span {
      float: right;
      img {
        width: 32px;
        height: auto;
      }
    }
  }
}
&--cookies {
  button {
    text-decoration: underline;
    color: $font-button-secondary;
    font-weight: 500;
    background: none;
    border: none;
    &:hover, &:focus, &:active {
      background: none !important;
      border: none;
      outline: none;
      box-shadow: none !important;
    }
  }
}
a {
  color: white;
  text-decoration: underline;
}
span {
  display: block;
}
// Desktop specific styling
@media (min-width: $tablet) {
  &--cookies {
    button {
      margin-bottom: -10px;
      font-size: 12px;
    }
  }
  &--email {
    span {
      a {
        font-size: 12px !important;
      }
    }
  }
}

  // Mobile specific styling
  @media (max-width: $tablet) {
    &--info {
     
    }
    &--email {
      order: 1;
      span {
        padding: 10px 0;
      }
    }
    &--social-media {
      order: 3;
      span {
        margin: 15px 0;
        display: block;
        width: 100%;
        text-align: center;
      }
    }
  }
  @media (max-width: $mobile) {
    &--info {
      order: 2;
    }
    &--email {
      order: 1;
    }
    &--social-media {
      order: 3;
    }
   
}

}