.side-bar {
// General styling
display: inline-block;
position: fixed;
top: 80px;
left: -200px;
width: 240px;
height: 400px;
transition: .2s all ease-in-out;
z-index: 1;
&--tab {
  position: relative;
  padding: 3px 20px;
  span {
    z-index: 1;
    position: relative;
    font-size: 22px;
    font-weight: bold;
    border-bottom: 2px solid rgba(74, 74, 74, 0.7);
  }
  i {
    z-index: 1;
  }
  .background-filler {
    border-radius: 0 5px 5px 0;
    background: #D8D8D8;
  }
  .fas {
    right: 5px;
    top: 9px;
    position: absolute;
    cursor: pointer;
    height: 20px;
    font-size: 18px;
    border-radius: 10px;
    background: #55555E;
    width: 30px;
    padding-left: 13px;
    padding-top: 1px;
    color: white;
  }
}
&--panel {
  padding: 0px 20px 20px;
  width: 200px;
  position: relative;
  height: 100%;
  .background-filler {
    background: #D8D8D8;
    border-radius: 0 0 5px  0;
  }
  form {
    position: relative;
    height: inherit;
    .select2 {
      &-container {
        display: block;
        width: 100% !important;
      }
      &-selection {
        border: 1px solid $font-general;
      }
    }
  }
}
label {
  margin-top: 5px;
  height: inherit;
  width: 100%;
  font-weight: 500;
}
&.open {
  left: 0;
  .fas {
    transform: rotate(180deg);
  }
}
&--cta_submit {
  background: #F0AB1D;
  border: 0;
  border-radius: 3px;
  box-shadow: 0 0 4px 1px rgba(0,0,0,0.2);
  width: 150px;
  font-size: 18px;
  line-height: 22px;
  font-weight: bold;
  color: white;
  padding: 5px 0;
  display: block;
  margin: auto;
  position: absolute;
  bottom: 40px;
  cursor: pointer;
}
&--cta_reset {
  position: absolute;
  bottom: 0;
  background: transparent;
  border: 0;
  font-size: 14px;
  text-decoration: underline;
}
// Desktop specific styling
@media (min-width: $tablet) {
}

  // Mobile specific styling
  @media (max-width: $tablet) {
    display: none;
  }
}