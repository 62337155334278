.about-hero-content {
// General styling

max-width: 730px;
color: #CCCCCC;
h1 {
    font-size: 30px;
    color: white;
}
hr {
    margin: 30px 0;
}
// Desktop specific styling
@media (min-width: $tablet) {
}

  // Mobile specific styling
  @media (max-width: $tablet) {
  }
}