.case-studies--case-card {
// General styling
min-width: 220px;
margin-top: 30px;
.card {
  &-header {
    border-bottom: 0;
    background: transparent;
    padding-left: 7px;
    div.circle {
      &:nth-of-type(2){
        display: none;
      }
      &:nth-of-type(3){
        display: none;
      }

      &.data-tool{
        background: $datatool;
      }
      &.open-data-portal {
        background: $open-data-portal;
      }
      &.data-journalism {
        background: $data-journalism;
      }
      &.research {
        background: $research;
      }
      &.media-analysis {
        background: $media-analysis;
      }
      &.accountability-platform {
        background: $accountability-platform;
      }
      &.citizens-and-city {
        background: $citizens-and-city;
      }
      &.toolkit {
        background: $toolkit;
      }
    }
  }
  &-body {}
  &-footer {
    border-top: 0;
    background: white;
    .upper-footer {
      padding: 5px 20px;
      background: white;
    }
    span {
      line-height: 27px;
      font-size: 16px;
    }
    .sub-footer {
      padding: 5px 20px;
      &.civic-data-lab {
        background: $brand-tertiary;
      }
      background: $brand-secondary;
      span {
        font-size: 14px;
        color: white;
      }
    }
    hr {
      width: 215px;
      border-top: 1px solid $brand-secondary;
      box-shadow: none;
      opacity: 1;
    }
  }
}
// Desktop specific styling
@media (min-width: $tablet) {
}

  // Mobile specific styling
  @media (max-width: $mobile) {
    margin: 20px 30px;
  }
}