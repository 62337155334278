.btn {
    transition: .2s all ease-in-out;
    &.disabled {
        cursor: not-allowed;
        opacity: .5;
    }
// General styling
&--secondary {

}
&--inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    + label {
        font-size: 1.25em;
        font-weight: 700;
        color: white;
        background-color: black;
        display: inline-block;
        cursor: pointer; /* "hand" cursor */
    }
    &:focus + label,
    + label:hover {
        background-color: red;
    }
}

// Desktop specific styling
@media (min-width: $tablet) {

}

  // Mobile specific styling
  @media (max-width: $tablet) {
  }
}