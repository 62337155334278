.modal {
  // General styling
  &-dialog {
      max-width: 1140px;
  }
  &-content {
    padding: 40px 50px 50px;
    [class*="modal-"] {
        padding: 0;
    }
}
&-header {
    padding-bottom: 0;
    border: 0;
    .close {
        span {
            font-size: 60px;
            font-weight: 500;
        }
        transition: .1s all ease-in-out;
    }
}
&-body {
    padding-top: 0;
.modal-only {
    display: block;
}
}

// Desktop specific styling
@media (min-width: $tablet) {
}

  // Mobile specific styling
  @media (max-width: $tablet) {
  }
}