.funders {
// General styling
p {
    text-align: center;
    font-weight: 500;
    font-style: oblique;
    line-height: 27px;
}
img {
    height: 120px;
    margin: auto;
    margin-bottom: 30px;
    max-width: 350px;
    display: block;
}
.col-md-4 {
    text-align: center;
}
h2 {
    margin-bottom: 70px;
}
// Desktop specific styling
@media (min-width: $tablet) {
}

  // Mobile specific styling
  @media (max-width: $tablet) {
  }
}