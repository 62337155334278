.hero {
  // General styling
position: relative;
transition: .2s all ease-in-out;
color: white;
background-size: cover;
background-repeat: no-repeat;
text-align: center;
height: 100vh;
margin-top: 60px;
img{
  width: 100%;
}
h1 {
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
  span {
    font-size: 30px;
    font-weight: inherit;
  }
}
a {
  color: white;
  text-decoration: underline;
}
p {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 200;
}

&--home-content {
  display: block;
  z-index: 2;
  flex-flow: column;
  max-width: 830px;
  height: 605px;
  position: relative;
  // padding: 70px 0 35px;
  // background-color: white;
  .image, .text {
    display: inline-block;
    padding: $module-padding;
    color: $font-general;
    max-width: 480px;
    z-index: 3;
  }
  &::before {
    background: url(/odd/static/dist/img/image_hero-banner_page.png);
    background-size: 100%;
    background-repeat: no-repeat;
    content: '';
    width: 200%;
    left: -50%;
    top: -5%;
    height: 120%;
    position: absolute;
    z-index: 1;
  }
}

// Desktop specific styling
@media (min-width: $desktop) {
  .rectangle-bits {
    img {
      &.sparkles {
        top: 420px;
      }
    }
  }
}

// Desktop specific styling
@media (min-width: $tablet) {
}

  // Mobile specific styling
  @media (max-width: $tablet) {
    &--home-content {
      text-align: center;
      top: 30px;
      position: relative;
    }
  .rectangle-bits {
    img {
      &.sparkles {
        top: 478px;
      }
      &.rectangles {
        top: 425px;        
      }
      &.grey-curtain {
        top: 386px;
      }
    }
  }
  }
  @media (max-width: $mobile) {
    padding: 0;
    top: 0;
    margin-bottom: 0;
    min-height:100vh;
    &--home-content {
      &::before {
        display: none;
      }
    }
    .rectangle-bits {
      display: none;
    }
    .hero--home-content {
     .image {
       width: 100%;
       img {
         width: 100%;
       }
     }
    }
      
    h1 {
      font-size: 20px;
    }
    p {
      font-size: 14px;
    }
  }
  // Small desktop specific styling
  @media (max-width: $desktop) and (min-width: $tablet) {
      margin-top: 120px !important;
  }
}
.background {
  width: 100%;
  height: 150vh;
  display: block;
  background-image: url('/odd/static/dist/img/image_bg_hero-banner.png');
  position: absolute;
  background-size: 100vw 100vh;
  background-repeat: no-repeat;
}