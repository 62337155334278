.our-team {
  // General styling
  margin-top: 75px;

  .padding-bottom {
    margin-bottom: 80px;
  }

  .profile {
    $diameter: 160px;
    width: $diameter;
    height: 335px;
    position: relative;
    background-repeat: no-repeat !important;
    &--info {
      background: white;
      bottom: 0;
      position: absolute;
      width: 100%;
      padding: 15px;
      span {
        width: 100%;
        text-align: center;
        display: block;
        &.name{
          text-transform: uppercase;
          font-size: 16px;
          display: block;
          text-align: center;
          line-height: 20px;
        }
        &.join_team_name {
          color: #4A4A4A;
          position: relative;
          bottom: 9px;
        }
        &.role{
          font-size: 16px;
          margin-top:5px;
          color: #9B9B9B;
          font-weight: 200;
        }
      }
    }
    &.abigail {
      background: url('/odd/static/dist/img/ocl-team_abi-kemper.JPG') top center/contain;
    }
    &.brian {
      background: url('/odd/static/dist/img/ocl-team_brian-oleary.JPG') top center/contain;
    }
    &.ayanda {
      background: url('/odd/static/dist/img/ocl-team_ayanda-nene.JPG') top center/contain;
    }
    &.callum {
      background: url('/odd/static/dist/img/ocl-team_callum-oberholzer.JPG') top center/contain;
    }
    &.carla {
      background: url('/odd/static/dist/img/ocl-team_carla-ziady.JPG') top center/contain;
    }
    &.matthew {
      background: url('/odd/static/dist/img/ocl-team_matt-adendorff.JPG') top center/contain;
    }
    &.megan {
      background: url('/odd/static/dist/img/ocl-team_meg-gericke.JPG') top center/contain;
    }
    &.paul {
      background: url('/odd/static/dist/img/ocl-team_paul-figueira.JPG') top center/contain;
    }
    &.richard {
      background: url('/odd/static/dist/img/ocl-team_rich-gevers.JPG') top center/contain;
    }
    &.sophie {
      background: url('/odd/static/dist/img/ocl-team_sophie-mcmanus.JPG') top center/contain;
    }
    &.wasim {
      background: url('/odd/static/dist/img/ocl-team_wasim.jpg') top center/contain;
    }
    &.thandeka {
      background: url('/odd/static/dist/img/ocl-team_thandi-bhengu.JPG') top center/contain;
    }
    //new team members
    &.dieumerci {
      background: url('/odd/static/dist/img/ocl-team_dee.jpg') top center/contain;
    }
    &.ella {
      background: url('/odd/static/dist/img/ocl-team_ella.jpg') top center/contain;
    }
    &.lerato {
      background: url('/odd/static/dist/img/ocl-team_lerato.jpg') top center/contain;
    }
    &.megan_m {
      background: url('/odd/static/dist/img/ocl-team_meg-m.jpg') top center/contain;
    }
    &.phakamani {
      background: url('/odd/static/dist/img/ocl-team_phakamani.jpg') top center/contain;
      background-size: 180px;
    }
    &.sarah {
      background: url('/odd/static/dist/img/ocl-team_sarah.jpg') top center/contain;
    }
    &.join_team {
      background: url('/odd/static/dist/img/ocl-team_join-team.jpg') top center/contain;
    }

  }

  .team {
    margin-top: 70px;

    a {
      color: #F0AB1D;
      font-size: 14px;
      text-decoration: underline;
      font: normal 400 16px/1.5 "Montserrat";
    }

    &-extended {
      .circle {
        margin-top: 15px;
      }
    }
  }

  // Desktop specific styling
  @media (min-width: $tablet) {
    .team-extended {
      .col-md-4 {
        text-align: center;
      }
    }
  }

  // Mobile specific styling
  @media (max-width: $mobile) {
    .col-6 {
      text-align: center;
      padding: 0 7.5px;
      .profile {
        width: 100%;
        &--info {
          bottom: 5px;
        }
      }
      .role {
        font-size: 14px;
      }
    }
    .team.no-gutters {
      margin-top: 0;
    }
  }
}

@media (min-width: 1200px) {
.no-gutters {
.col-xl-3 {
    flex: 0 0 19.66667%;
    max-width: 19.66667%;
}
}
}