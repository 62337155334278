iframe {
  &#launcher {
    right: calc((100vw - 1170px)/2) !important;
    bottom: 3px !important;
  }
  &#webWidget {
    right: calc((100vw - 1170px)/2) !important;
    bottom: 5px !important;
  }
}
// .info-button {
//   // General styling

//     position: fixed;
//     right: 20px;
//     bottom: 50px;
//     width: 200px;
//     [aria-expanded="true"]{
//       i:before {
//         content: "X";
//         font-family: 'Montserrat';
//         font-weight: 900;
//       }
//     }
//   &--toggle {
//     color: #FF8F36;
//     width: 40px;
//     height: 40px;
//     border: 4px solid #FF9036;
//     border-radius: 20px;
//     text-align: center;
//     font-size: 21px;
//     cursor: pointer;
//     box-shadow: 0 0 7px 2px rgba(0, 0, 0, 0.1);
//     margin: auto;
//     margin-bottom: 20px;
//     transition: all .2s;
//   }
//   &--content {
//     max-width: 250px;
//     .card {
//       &-header {
//         background: $font-general;
//       }
//     }
// }


// Desktop specific styling
@media (min-width: $tablet) {
}

  // Mobile specific styling
  @media (max-width: $tablet) {
  }
// }