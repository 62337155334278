/**
 * Basic typography style for copy text
 */

body {
  color: $font-general;
  font: normal 16px / 1.5 $font-family-primary;
  font-family: $font-family-primary;
  font-weight: 400;
}
.fa, .fas, .far, .fab  {
  font-family: 'Fontawesome';
  margin-left: 5px;
}

h1 {
  font: $h1;
}
h2 {
  font: $h2;
}
h3 {
  font: $h3;
}
h4 {
  font: $h4;
}
h5 {
  font: $h5;
}
p {
  font: $p;
}
span {
  font: $span;
}

a {
  color: $font-general;
  &.inline {
    color: $font-button-secondary;
    text-decoration: underline;
  }
}